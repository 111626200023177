// @ts-nocheck

import {
    Vue, Options
  } from 'vue-class-component'
  import Header from '@/components/Header.vue'
  import swal from 'sweetalert'
  import { userBillFactory } from '@/http/user-bill'
  import { masterDataFactory } from '@/http/master-data'
  import constants from '@/constants/constants'
  import Utils from '@/utils/utils'
  @Options({
    components: {
      Header
    },
    data() {
      return {
        dataReady: false,
        masterBills: [],
        months: [],
        month: [],
        students: [],
        billTypes: [],
        studentBills: [],
        totalBill: {
          spp: 0,
          kegiatan: 0,
          ujian: 0
        },
        schoolYears: [],
        classes: [],
        data_master: {
          school_years: "1",
          bill_types: "1",
          classes: "1",
          months: '1'
        },
        param: {
          school_year_id: 0,
          class_id: ''
        },
        search: ''
      }
    },
    watch: {
      '$route.query': {
        handler: 'loadData',
        deep: true
      }
    },
    methods: {
      tableDataClass(data:any) {
        if (!data || data == undefined) return {};

        var is_paid = 1
        var duedate, deadlinedate
        var d1 = 0
        var d3 = 0
        var isSuccess, isPending, isDeadline = false
        data.forEach((value:any) => {
          if (value.status != constants.BILL_PAID) {
            is_paid = 0
          }
          if (is_paid == 0) {
            duedate = new Date(value.due_date)
            deadlinedate = new Date(value.deadline_date)
            if (d1 == 0 || d1 > deadlinedate.getTime()) {
              d1 = deadlinedate.getTime()
            }
            if (d3 == 0 || d3 > duedate.getTime()) {
              d3 = duedate.getTime()
            }
          }
        })
        if (is_paid == 1) {
          isSuccess = true
        } else if (is_paid == 0) {
          var datenow = new Date()
          datenow.setHours(0,0,0,0)
          var d2 = datenow.getTime()
          if (d2 >= d1) {
            // overdue
            isDeadline = true
          } else if (d2 > d3 && d3 < d1) {
            // duedate
            isPending = true
          }
        }
        return {
          'bg-success-strip text-success': isSuccess,
          'bg-warning-strip text-warning': isPending,
          'bg-danger-strip text-danger': isDeadline,
        }
      },
      getMonth: function(date:any) {
        if (date != '' && date != undefined) {
          const d = date.split("-")
          const month  = d[2]
          return this.months[month].id
        } else {
          return ''
        }
      },
      getMonthIndex: function(date:any) {
        if (date != '' && date != undefined) {
          const d = date.split("-")
          const month  = d[2]
          return month
        } else {
          return 0
        }
      },
      sum: function(total:any, num:any) {
        return total + num
      },
      setRouter: function () {
        this.$router.push({
          path: '/',
          query: {
            school_year: this.param.school_year_id,
            class: this.param.class_id,
            t: +new Date()
          }
        })
      },
      loadData: function() {
        var school_year = this.$route.query.school_year
        if (school_year !== undefined && school_year !== '') {
          this.param.school_year_id = parseInt(school_year)
        }
        var classes = this.$route.query.class
        if (classes !== undefined && classes) {
          this.param.class_id = parseInt(classes)
        }

        if (!this.param.school_year_id || !this.param.class_id) {
          return
        }

        Promise.all([
          userBillFactory.userBillSummary(this.param),
        ]).then(results => {
          this.studentBills = results[0].data.bill_users
          this.students = results[0].data.students
          var student_data :any = []
          for (let type in this.billTypes) {
            const i = this.billTypes[type].id
            var tempstudent :any = []
            for (let j in this.studentBills[i]) {
              var temp :any = []
              const user_bill = this.studentBills[i][j]
              var totalAmount :any = []
              var bills :any = []
              for (let k in user_bill) {
                const bill = this.studentBills[i][j][k]
                const index = this.getMonthIndex(bill.id)
                const month = this.getMonth(bill.id)
                if (bills[month] == undefined) {
                  bills[month] = []
                }
                bills[month].push(bill)
                totalAmount[index] = Number.isInteger(totalAmount[index]) ? (parseInt(totalAmount[index]) + parseInt(bill.total_amount)) : parseInt(bill.total_amount) || 0;
              }
              temp.id = j
              temp.name = this.students[j].name
              temp.nis = this.students[j].nis
              temp.nisn = this.students[j].nisn
              temp.detail = totalAmount
              temp.detail_bill = bills
              temp.total_bill = totalAmount.reduce(this.sum)
              tempstudent.push(temp)
            }
            student_data[i] = tempstudent
          }
          this.studentBills = student_data
          // console.log(student_data)
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        })
      },
      loadDataMaster: function() {
        Promise.all([
          masterDataFactory.master_datas(this.data_master),
        ]).then(results => {
          this.schoolYears = results[0].data.school_years
          var month = results[0].data.months
          var months :any = []
          Object.keys(month).forEach((key:any) => {
            month[key].index = key
            months.push(month[key])
          })
          months.sort((a:any, b:any) => (a.seq > b.seq) ? 1 : -1)
          this.month = months
          this.months = results[0].data.months
          var billTypes = results[0].data.bill_types
          billTypes.forEach((type:any,_:any,__:any) => {
            if (type.status == 1) {
              this.billTypes.push(type)
            }
          })
          this.classes = results[0].data.classes
          // this.param.class_id = this.classes[0].class_id
          this.schoolYears.forEach((school_year:any) => {
            if (school_year.status == "Active") {
              this.param.school_year_id = school_year.school_year_id
            }
          })
          this.loadData()
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        })
      },
      toLocaleString: function(val:any) {
        return Utils.toLocaleString(val)
      }
    },
    computed: {
      filteredData: function() {
        var filter : any = []
        var filtered : any = []
        if (this.search != '' && this.search.length > 2) {
          var search = this.search && this.search.toLowerCase()
          for (let type in this.billTypes) {
            const i = this.billTypes[type].id
            filter = this.studentBills[i].filter( function (type:any,_:any,__:any) {
              if (type.name.toLowerCase().includes(search) || type.nis.toLowerCase().includes(search)) {
                return type
              }
            })
            filtered[i] = filter
          }
          return filtered
        } else {
          return this.studentBills
        }
      }
    },
    async mounted () {
      await this.loadDataMaster()

      this.dataReady = true
    },
  })

  export default class Dashboard extends Vue {}
