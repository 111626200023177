<template v-if="dataReadY">
  <Header title="" link1="Rekap"></Header>
  <!-- <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <router-link tag="a" to="/bill" class="btn btn-circle btn-gradient mr-1">
            <ion-icon name="pencil"></ion-icon> Atur Tagihan
          </router-link>
          <router-link tag="a" to="/bill/user" class="btn btn-circle btn-info mr-1">
            <ion-icon name="search-outline"></ion-icon> Tagihan Siswa
          </router-link>
          <router-link tag="a" to="/payment" class="btn btn-circle btn-warning">
            <ion-icon name="receipt-outline"></ion-icon> Pembayaran
          </router-link>
        </div>
      </div>
    </div>
  </div> -->
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="float-end">
                <div class="d-inline">
                  <label for="">Tahun Ajar:</label>
                  <select @change="setRouter()" v-model="param.school_year_id" class="d-inline text-primary form-select form-select-noborder">
                    <option
                      v-for="sy in schoolYears"
                      :key="sy.school_year_id"
                      :label="sy.name"
                      :value="sy.school_year_id">
                    </option>
                  </select>
                </div>
                <!-- <div class="v-divider"></div>
                <div class="d-inline">
                  <label for="">Semester :</label>
                  <select class="d-inline text-primary form-select form-select-noborder"
                    aria-label="Default select example">
                    <option value="1">Ganjil</option>
                    <option value="2">Genap</option>
                  </select>
                </div> -->
                <div class="v-divider"></div>
                <div class="d-inline">
                  <label for="">Kelas :</label>
                  <select @change="setRouter()" v-model="param.class_id" class="d-inline text-primary form-select form-select-noborder">
                    <option value="">Semua Kelas</option>
                    <option
                      v-for="c in classes"
                      :key="c.class_id"
                      :label="c.name"
                      :value="c.class_id">
                    </option>
                  </select>
                </div>
                <div class="v-divider"></div>
                <div class="d-inline">
                  <label for="">Cari :</label>
                  <input class="form-control" type="text" v-model="search" style="display:inherit; margin-left:10px;width:auto;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <ul class="nav nav-tabs" id="billTabs" role="tablist">
            <li v-for="(type,index) in billTypes" :key="index" class="nav-item" role="presentation">
              <a class="nav-link" :class="{'active' : index == 0}" :id="type.id+'-tab'" data-bs-toggle="tab" aria-current="page"
                :href="'#'+type.id" role="tab" :aria-controls="type.id" aria-selected="true">{{ type.name }}</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade table-responsive" :class="{'show active' : index == 0}" v-for="(type,index) in billTypes" :key="index" :id="type.id" role="tabpanel" :aria-labelledby="type.id+'-tab'">
              <div v-if="!filteredData[type.id]" class="msg-box warning">Pilih dahulu kelas dan tahun ajar</div>
              <table v-if="filteredData[type.id]" class="table table-light table-borderless table-data">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>NIS <ion-icon name="caret-up"></ion-icon></th>
                    <th>Nama </th>
                    <th v-for="(m, index) in month" :key="index" class="text-end">{{ m.id }}</th>
                    <th class="text-end">Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                   <tr v-for="(student, i) in filteredData[type.id]" :key="i++">
                    <td>{{ i }}</td>
                    <td>{{ student.nis }}</td>
                    <td class="fw-bold sticky-left">
                      <router-link :to="'/bill/'+student.id" tag="a">{{ student.name }}</router-link>
                    </td>
                    <td class="text-end" v-for="(m, index) in month" :key="index" v-bind:class="tableDataClass(student.detail_bill[m.id])">{{ toLocaleString(student.detail[m.index]) }} </td>
                    <td class="text-end fw-bold">{{ toLocaleString(student.total_bill) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-md-6">
                <!-- <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                  </ul>
                </nav> -->
              </div>
              <div class="col-md-6">
                <ol class="horizontal-list">
                  <li class="horizontal-list-item green">
                      Lunas
                  </li>
                  <li class="horizontal-list-item orange">
                      Due Date
                  </li>
                  <li class="horizontal-list-item red">
                      Overdue
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script language="ts" src="./scripts/dashboard.ts"></script>
